
@import url('https://fonts.googleapis.com/css2?family=Bonheur+Royale&display=swap');

*{
    margin: 0;
    padding:0;
}

html{
    background-image: url("/public/whitemarble.jpg") ;
    width: 100%;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
background-color: white;
color:black;
}

